import throttle from "lodash/throttle";
import { useEffect, useState } from "react";

export function useScrollPosition(wait = 100) {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollPosition(window.scrollY);
    }, wait);

    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [wait]);

  return scrollPosition;
}
