import { useOS } from "./useOS";

export function useTryForFreeLink() {
  const os = useOS();
  const iOSLink =
    "https://apps.apple.com/us/app/zest-cooking/id1595938390?ppid=9c63e244-6bf7-4c2a-b89b-a074ab70021f";
  const androidLink =
    "https://play.google.com/store/apps/details?id=co.zestapp.mobile&hl=en_US&gl=US";
  return os.name?.match(/Android/) ? androidLink : iOSLink;
}
