import { animated, useSpring } from "@react-spring/web";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import { useEffect, useState } from "react";

import { useScrollPosition } from "~/hooks";
import { useTryForFreeLink } from "~/hooks/useTryForFreeLink";
import { events, track } from "~/services/analytics";
import GreenLink from "./GreenLink";

import Loma from "~/assets/svg/Loma";

export interface HeaderProps {
  linkText?: string;
  linkHref?: string;
  forceOpaque?: boolean;
}

const Header = ({
  linkText = "Try for free",
  linkHref = undefined,
  forceOpaque = false,
}: HeaderProps) => {
  const link = useTryForFreeLink();

  const scrollPosition = useScrollPosition(50);
  const [isOpaque, setIsOpaque] = useState(false);
  useEffect(() => {
    if (scrollPosition > 50) {
      setIsOpaque(true);
    } else {
      setIsOpaque(false);
    }
  }, [scrollPosition]);
  const styles = useSpring({
    backgroundColor:
      isOpaque || forceOpaque
        ? "rgba(255, 255, 255, 1)"
        : "rgba(255, 255, 255, 0)",
    boxShadow:
      isOpaque || forceOpaque
        ? "0 2px 4px rgba(0, 0, 0, 0.025)"
        : "0 2px 4px rgba(0, 0, 0, 0)",
  });

  return (
    <animated.header
      className="fixed z-20 w-full"
      style={styles}
    >
      <div
        className={clsx([
          "container relative flex h-20 items-center pr-4",
          "2xl:h-30 mx-auto 2xl:pr-0",
        ])}
      >
        <a
          href="/"
          className="block flex h-full items-center overflow-hidden transition-opacity hover:opacity-80"
        >
          <div
            className={clsx([
              "absolute bottom-2.5 left-0 h-24 w-14",
              "2xl:h-32 2xl:w-32",
            ])}
          >
            <Loma
              width="100%"
              height="100%"
            />
          </div>
          <span
            className={clsx([
              "ml-12 font-logo text-2xl text-text-black",
              "2xl:ml-24 2xl:text-4xl",
            ])}
          >
            zest
          </span>
        </a>
        <div className="ml-auto">
          <Link
            to="/team"
            className={clsx([
              "mr-12 hidden font-bold text-lg hover:underline",
              "sm:inline-block",
            ])}
          >
            Team
          </Link>
          <GreenLink
            href={linkHref ? linkHref : link}
            text={linkText}
            size="medium"
            target="_blank"
            onClick={() => {
              track(events.CLICKED_DOWNLOAD, {
                from: "header",
              });
            }}
          />
        </div>
      </div>
    </animated.header>
  );
};

export default Header;
