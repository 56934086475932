import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={95}
    height={174}
    fill="none"
    viewBox="0 0 95 174"
    preserveAspectRatio="meet"
    {...props}
  >
    <path
      fill="#00CB75"
      d="M55.526 0H34.584a4.245 4.245 0 0 0-4.244 4.246v49.011c0 2.344 1.9 4.245 4.244 4.245h20.941c2.345 0 4.245-1.9 4.245-4.245V4.246C59.77 1.9 57.87 0 55.525 0Z"
    />
    <path
      fill="#7BB851"
      d="M59.325 77.865 26.792 12.513a4.245 4.245 0 0 0-5.692-1.908L2.353 19.938A4.245 4.245 0 0 0 .445 25.63l32.534 65.352a4.245 4.245 0 0 0 5.692 1.908l18.746-9.333a4.245 4.245 0 0 0 1.908-5.692Z"
    />
    <path
      fill="#00AB5E"
      d="M92.646 9.778 73.9.446a4.245 4.245 0 0 0-5.692 1.908L31.022 77.05a4.227 4.227 0 0 0-.682 2.31l1.198 31.233h25.635l2.597-25.251L94.555 15.47a4.245 4.245 0 0 0-1.909-5.692Z"
    />
    <path
      fill="url(#b)"
      d="M51.368 100.892a31.337 31.337 0 0 0-20.124 2.49s-9.53 46.977.65 56.934c3.932 3.847 16.309 4.41 23.208 1.841 1.506-.561 2.753-1.27 3.592-2.128 9.95-10.173-.649-56.934-.649-56.934a31.318 31.318 0 0 0-6.677-2.203Z"
    />
    <path
      fill="#0D5C48"
      d="M45.18 149.302a2.421 2.421 0 0 1-1.368-.392 1.493 1.493 0 0 1-.501-.549 1.558 1.558 0 0 1-.183-.733c0-.086.033-.169.092-.23a.308.308 0 0 1 .222-.096c.083 0 .163.035.221.096a.332.332 0 0 1 .092.23c0 .704.74 1.026 1.426 1.026.687 0 1.427-.321 1.427-1.026 0-.086.033-.169.092-.23a.309.309 0 0 1 .221-.096c.083 0 .163.035.222.096a.332.332 0 0 1 .092.23c-.001 1.098-1.034 1.674-2.054 1.674Z"
    />
    <path
      fill="#FFA1AC"
      d="M59.969 147.671c0 1.33-1.39 2.409-3.106 2.41-1.716.001-3.108-1.077-3.108-2.407-.001-1.33 1.39-2.409 3.105-2.409 1.717-.001 3.108 1.076 3.109 2.406ZM36.198 147.671c0 1.33-1.39 2.409-3.106 2.41-1.716.001-3.108-1.077-3.108-2.407-.001-1.33 1.39-2.409 3.105-2.409 1.717-.001 3.108 1.076 3.109 2.406Z"
    />
    <path
      fill="#fff"
      d="M57.034 141.485c6.168 0 11.169-5.001 11.169-11.169s-5-11.169-11.169-11.169c-6.168 0-11.169 5.001-11.169 11.169s5 11.169 11.169 11.169Z"
    />
    <path
      fill="#0D5C48"
      d="M63.9 118.385c-.276 0-.541-.109-.737-.304a6.509 6.509 0 0 0-5.859-1.633 1.047 1.047 0 0 1-1.158-1.554c.143-.239.374-.412.644-.48a8.58 8.58 0 0 1 7.838 2.181 1.045 1.045 0 0 1-.148 1.626c-.175.116-.38.176-.59.174l.01-.01ZM25.897 118.384a1.04 1.04 0 0 1-.98-.649 1.048 1.048 0 0 1 .242-1.151 8.592 8.592 0 0 1 7.838-2.181 1.06 1.06 0 0 1 .644.48 1.045 1.045 0 0 1-.06 1.164 1.033 1.033 0 0 1-.303.274 1.043 1.043 0 0 1-.795.116 6.51 6.51 0 0 0-5.858 1.639 1.042 1.042 0 0 1-.728.308ZM55.954 138.684a8.368 8.368 0 1 0 0-16.735 8.368 8.368 0 0 0 0 16.735Z"
    />
    <path
      fill="#fff"
      d="M32.763 141.485c6.169 0 11.169-5.001 11.169-11.169s-5-11.169-11.169-11.169c-6.168 0-11.168 5.001-11.168 11.169s5 11.169 11.168 11.169Z"
    />
    <path
      fill="#0D5C48"
      d="M33.844 138.684a8.368 8.368 0 1 0 0-16.736 8.368 8.368 0 0 0 0 16.736Z"
    />
    <path
      fill="#fff"
      d="m37.948 126.664-1.655.945-.906 1.728-.905-1.728-1.656-.945 1.656-.945.905-1.727.906 1.727 1.655.945ZM59.965 126.664l-1.656.945-.906 1.728-.905-1.728-1.656-.945 1.656-.945.905-1.727.906 1.727 1.656.945ZM32.47 129.573l-.502.274-.274.503-.275-.503-.502-.274.502-.275.275-.502.274.502.502.275ZM54.531 129.573l-.502.274-.275.503-.274-.503-.503-.274.503-.275.274-.502.275.502.502.275Z"
    />
    <defs>
      <linearGradient
        id="b"
        x1={43.19}
        x2={44.355}
        y1={99.586}
        y2={164.061}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C7F45D" />
        <stop
          offset={0.573}
          stopColor="#E8F9AF"
        />
        <stop
          offset={0.797}
          stopColor="#FFF6E7"
        />
        <stop
          offset={0.953}
          stopColor="#FFF6E7"
        />
      </linearGradient>
      <filter
        id="a"
        width={43.76}
        height={71.5}
        x={23.195}
        y={100.227}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite
          in2="hardAlpha"
          operator="out"
        />
        <feColorMatrix values="0 0 0 0 0.388235 0 0 0 0 0.396078 0 0 0 0 0.4 0 0 0 0.1 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_88_1545"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_88_1545"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;
