import clsx from "clsx";
import React from "react";

export interface GreenLinkProps {
  text: string;
  href: string;
  size: "small" | "medium" | "large" | "xlarge";
  variant?: "green" | "red";
  target?: string;
  onClick?: () => void;
}

const GreenLink = ({
  text,
  href,
  size,
  variant = "green",
  target = undefined,
  onClick = () => {},
}: GreenLinkProps) => {
  return (
    <a
      className={clsx([
        "inline-block rounded-3xl rounded-md px-8 py-2.5 font-apercu font-bold text-white transition-colors",
        variant === "green" && "bg-orange-main hover:bg-orange-dark",
        variant === "red" && "bg-brand-dark hover:bg-brand-hover",
        size === "small" && "text-xs 2xl:text-sm",
        size === "medium" && "text-sm 2xl:text-base",
        size === "large" &&
          "2xl:rounded-3 text-lg 2xl:px-32 2xl:py-6 2xl:text-2xl",
        size === "xlarge" &&
          "rounded-[40px] px-16 py-4 text-xl md:rounded-[100px] md:px-40 md:py-5 md:text-3xl 2xl:rounded-[100px] 2xl:px-56 2xl:py-7 2xl:text-4xl",
      ])}
      href={href}
      target={target}
      onClick={onClick}
    >
      {text}
    </a>
  );
};

export default GreenLink;
